import { lazy } from 'react'

const Signin = lazy(() => import('../pages/signin'))
const ReportChannel = lazy(() => import('../pages/report_channel'))
const ReportChannelPhone = lazy(() => import('../pages/report_channel_phone'))
const ReportChannelEncrypt = lazy(() => import('../pages/report_channel_encrypt'))
const ReportChannelPublicHeader = lazy(() => import('../pages/report_channel_publicheader'))
const ReportChannelUserData = lazy(() => import('../pages/report_channel_userdata'))

//华美
const Longin = lazy(() => import('../pages/login'))
const Complain = lazy(() => import('../pages/ComplainPage'))
const ReportDataList = lazy(() => import('../pages/report_list'))
const Demo = lazy(() => import('../pages/demo'))
const DemoFoot = lazy(() => import('../pages/demo_ai_foot'))
const ReportAnalyst = lazy(() => import('../pages/report_channel_encrypt_callback'))
const ReportResult = lazy(() => import('../pages/report_result'))
const DownloadReport = lazy(() => import('../pages/down_report'))
const ErrorPage = lazy(() => import('../pages/error_page'))
// const PhysicalEaxmReport = lazy(() => import('../pages/physical_examination_report'))
const PhysicalEaxmReport = lazy(() => import('../pages/physical_examination_report'))
// 固定展示结果，带有中医建议
const PhysicalEaxmReportChnMedFix = lazy(() => import('../pages/physical_examination_report_chn_advice_fix'))
const PhysicalEaxmReportUploadChnMedFix = lazy(() => import('../pages/physical_examination_upload_chn_advice_fix'))
// 真实展示结果，带有中医建议
const PhysicalEaxmReportChnMed= lazy(() => import('../pages/physical_examination_report_chn_advice'))
const PhysicalEaxmReportUploadChnMed = lazy(() => import('../pages/physical_examination_upload_chn_advice'))

const PhysicalEaxmReportCallback = lazy(() => import('../pages/physical_examination_report_callback'))
const PhysicalEaxmReportHos = lazy(() => import('../pages/physical_examination_report_enc_id_hos'))
const PhysicalEaxmReportUpload = lazy(() => import('../pages/physical_examination_upload'))
const PhysicalEaxmUploadCallBack = lazy(() => import('../pages/physical_examination_upload_callback'))
const PhysicalEaxmReportPhoneUpload = lazy(() => import('../pages/physical_examination_phone_test_upload'))
const ChnMedReportUpload = lazy(() => import('../pages/chn_med_report_upload'))
const ChnMedReportView = lazy(() => import('../pages/chn_med_report_view'))
const ChnMedReportUploadFix = lazy(() => import('../pages/chn_med_report_upload_fix'))
const ChnMedReportViewFix = lazy(() => import('../pages/chn_med_report_view_fix'))
//健康饮食
const Diet = lazy(() => import('../pages/diet'))
const DietReport = lazy(() => import('../pages/diet_report'))
const DietDemo = lazy(() => import('../pages/diet_demo'))
const DietReportDemo = lazy(() => import('../pages/diet_report_demo'))
const TakePhoto = lazy(() => import('../pages/TakePhoto'))
const Identifying = lazy(() => import('../pages/Identifying'))
const DietReportDetail = lazy(() => import ('../pages/diet_report_detail'))

const Router = [
  //华美
  {
    path: '/',
    name: '精准医疗',
    component: ReportChannel,
    // component: Demo,
  },
  {
    path: '/login',
    name: '登录页面',
    component: Longin,
  },
  {
    path: '/complainPage',
    name: '投诉页面',
    component: Complain,
  },
  {
    path: '/precision_report',
    name: '精准医疗',
    component: ReportChannelPhone,
  },
  {
    path: '/precision_report_enc',
    name: '精准医疗',
    component: ReportChannelEncrypt,
  },
  {
    path: '/report_list',
    name: '我的报告列表',
    component: ReportDataList,
  },
  {
    path: '/demo',
    name: '精准医疗',
    component: Demo,
  },
  //算法备案用加了注脚的页面
  {
    path: '/aipre',
    name: '精准医疗',
    component: DemoFoot,
  },
  {
    path: '/precision_report_enc_cb',
    name: '精准医疗',
    component: ReportAnalyst,
  },
  
  {
    path: '/report-result',
    name: '报告结果',
    component: ReportResult,
  },
  // 平台试用
  {
    path: '/demomodel',
    name: '精准医疗',
    component: Demo,
  },
  {
    path: '/fortest',
    name: '精准医疗',
    component: Demo,
  },
  {
    path: '/channel',
    name: '精准医疗',
    component: Signin,
  },
  {
    path: '/download/:phone/:id',
    name: '下载报告',
    component: DownloadReport,
  },
  {
    path: '/channeltest',
    name: '精准医疗test',
    component: ReportChannelUserData,
    // component: Demo,
  },
  {
    path: '/channelpubhead',
    name: '精准医疗',
    component: ReportChannelPublicHeader,
  },
  {
    path: '/channelpubhead',
    name: '精准医疗',
    component: ReportChannelPublicHeader,
  },
  {
    path: '/phyexam-upload',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  {
    path: '/phyexam-upload-ph',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  {
    path: '/phyexam-upload-model',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  //详细推荐医生版本
  {
    path: '/phyexam-upload-doc-detail',
    name: '体检报告解读',
    component: PhysicalEaxmReportPhoneUpload,
  },
  {
    path: '/phyexamreport/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReport,
  },
  // 固定中医理疗建议的体检报告解读结果报告上传页
  {
    path: '/phyexam-upload-med-fix',
    name: '体检报告解读',
    component: PhysicalEaxmReportUploadChnMedFix,
  },
  // 固定中医理疗建议的体检报告解读结果展示页
  {
    path: '/phyexamreport_chn_med/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportChnMedFix,
  },
  // 真实中医理疗建议的体检报告解读结果报告上传页
  {
    path: '/phyexam-upload-med-chn-adv',
    name: '体检报告解读',
    component: PhysicalEaxmReportUploadChnMed,
  },
  // 真实中医理疗建议的体检报告解读结果展示页
  {
    path: '/phyexamreport_chn_med_adv/:uid',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportChnMed,
  },
  {
    path: '/phyexam_report_cb',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportCallback,
  },
  // 用于对接医院，展示结果页面
  {
    path: '/phyexam_report_enc_id_hos',
    name: '体检报告分析结果',
    component: PhysicalEaxmReportHos,
  },
  {
    path: '/phyexam_upload_cb',
    name: '体检报告解读',
    component: PhysicalEaxmUploadCallBack,
  },

  {
    path: '/error/:errContent',
    name: '精准医疗',
    component: ErrorPage,
    // component: Demo,
  },
  //中医问诊
  {
    path: '/chnmedrepupload',
    name: '中医问诊',
    component: ChnMedReportUpload,
  },
  {
    path: '/chnmedrepview/:uid',
    name: '中医问诊报告',
    component: ChnMedReportView,
  },
  //中医问诊-固定结果展示用
  {
    path: '/chnmedrepuploadfix',
    name: '中医问诊',
    component: ChnMedReportUploadFix,
  },
  {
    path: '/chnmedrepviewfix/:uid',
    name: '中医问诊报告',
    component: ChnMedReportViewFix,
  },

  //健康饮食
  {
    path: '/diet',
    name: '健康饮食',
    component: Diet,
  },
  {
    path: '/dietreport/:uid',
    name: '健康饮食',
    component: DietReport,
  },
  {
    path: '/dietdemo',
    name: '健康饮食',
    component: DietDemo,
  },
  {
    path: '/dietreportdemo/:uid',
    name: '健康饮食',
    component: DietReportDemo,
  },
  {
    path: '/takephoto',
    name: '拍照',
    component: TakePhoto,
  },
  {
    path: '/identifying',
    name: '正在识别',
    component: Identifying
  },
  {
    path: '/dietreportdetail/:uid',
    name: '识别结果',
    component: DietReportDetail
  }
]
export default Router
